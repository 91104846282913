import { BridgeComponent } from "@hotwired/hotwire-native-bridge"

// Connects to data-controller="bridge--menu-button"
export default class extends BridgeComponent {
  static component = "menu-button"

  connect() {
    super.connect()

    this.send("connect", {}, () => {
      if (document.querySelectorAll("[data-controller='ruby-ui--sheet-content']").length != 0) {
        document.querySelector("[data-action='click->ruby-ui--sheet-content#close']").click()
      } else {
        document.querySelector("[data-action='click->ruby-ui--sheet#open']").click()
      }
    })
  }
}
